.app {
  min-height: 100%;
  min-width: 100%;
}

.app-header {
  background-color: black;
  color: white;
  padding-top: 20px;
  padding-left: 2%;
  padding-right: 2%;
}

.nav-bar {
  color: white;
  font-family: "Calibri Light";
}

.nav-text {
  color: white;
  font-family: "Calibri Light";
}

.nav-text.active {
  color: darkgray;
}

.dark-background {
  background-color: black;
}

.white {
  color: white;
}

.font-calibri {
  font-family: "Calibri", sans-serif;
}

.centered-text {
  text-align: center;
}

.pad-top-10px {
  padding-top: 20px;
}

.pad-top-10-percent {
  padding-top: 10%;
}

.pad-top-5-percent {
  padding-top: 5%;
}

.pad-left-5-percent {
  padding-left: 5%;
}

.rounded-corners-5px {
  border-radius: 5px;
}

.margin-x-10px {
  margin-right: 5px;
  margin-left: 5px;
}

.height-100-percent {
  height: 100%;
}

.height-95-percent {
  height: 95%;
}

.horizontal-white-line {
  margin: 0px;
  padding: 0px;
  color: white;
}
body {
  height: 100%;
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100%
}

#root {
  height: 100%;
}
